import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TestHistoryService } from 'src/app/core/services/test-history.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { TestHistory } from 'src/app/shared/models/test-history.model';

@Component({
  selector: 'app-test-history',
  templateUrl: './test-history.component.html',
  styleUrls: ['./test-history.component.scss']
})
export class TestHistoryComponent implements OnInit {
  testhistory: TestHistory[] = []

  constructor(private testHistoryService: TestHistoryService, private utilityService: UtilityService, private router: Router) { }

  ngOnInit(): void {
    this.getTestHistory();
  }

  getTestHistory() {
    this.testHistoryService.getTestHistory(this.utilityService.getFromStorage('customerId'))
      .subscribe((res: TestHistory[]) => {
        this.testhistory = res;
      });
  }

  routeToReport(test: TestHistory) {
    this.utilityService.saveToStorage('selectedTest', test); 
    this.utilityService.navigate('/consultation/packagereport');

  }

}
