import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { APIEndPoint } from 'src/app/core/https/ApiEndPoint';
import { RestApiService } from 'src/app/core/https/rest-api.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { MiddleService } from 'src/app/core/services/middle.service';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-call-dispose',
  templateUrl: './call-dispose.component.html',
  styleUrls: ['./call-dispose.component.scss'],
})
export class CallDisposeComponent implements OnInit {
  completeCall: boolean = false;
  incompleteCall: boolean = false;
  selectedItemsList = [];
  selectedReasons = [];
  callStatus: Number = 0;
  prescriptionSentStatus: number = 0;
  incompleteCallReason: string = 'call complete';
  isPrescriptionSent: boolean = false;
  clicked = false;
  
  constructor(
    public toastrService: ToasterService,
    private router: Router,
    private utilityService: UtilityService,
    private restAPIService: RestApiService,
    public dialogRef: MatDialogRef<CallDisposeComponent>,
    private readonly middleService: MiddleService
  ) { }

  ngOnInit(): void {
    this.middleService.isPrescriptionSent$.subscribe(
      (action: boolean) => (this.isPrescriptionSent = action)
    );
  }

  changeSelection(reasonNAME: string) {
    this.incompleteCallReason = reasonNAME;
  }

  callComp(){
    if(sessionStorage.getItem("prescriptionStatus")){
      this.completeCallStatus(1)
      this.close()
      this.dialogRef.close(true);
    }else{
      this.toastrService.pop(
        'warning',
        null,
        'Please complete and send prescription!'
      );
      sessionStorage.removeItem("prescriptionStatus")
    } 
  }

  callInComplete(value: any) {
    this.completeCall = false;
    this.incompleteCall = true;
    this.prescriptionSentStatus = value;
    this.incompleteCallReason = 'Dispose Doctor';
    this.callStatus = value;
  }

  completeCallStatus(value: any) {
    this.completeCall = true;
    this.incompleteCall = false;
    this.prescriptionSentStatus = value;
    this.callStatus = value;
    this.incompleteCallReason = 'call complete';
  }

  close() {
    this.callDisposeStatus();
    this.dialogRef.close(true);
    this.utilityService.navigate('/dashboard');
  }

  private moveUserToDashboard(): void {
    this.restAPIService
      .invoke(APIEndPoint.MOVE_DASHBOARD, null, {
        consultationId: this.utilityService.getFromStorage('consultationId'),
      })
      .subscribe((data: any) => {
        // if (data['checkValue'] === true) {
        //   this.utilityService.navigate('/dashboard');
        // }
         //this.utilityService.navigate('/dashboard');
         this.utilityService.removeFromStorage("consultationId")
         this.utilityService.removeFromStorage("customerId")
         window.location.href = "https://videoapp.stetho.hindustanlab.com";
      });
  }

  callDisposeStatus = () => {
    let reqBody = {
      callStatus: this.callStatus,
      prescriptionSentStatus: this.prescriptionSentStatus,
      consultationId: this.utilityService.getFromStorage('consultationId'),
      customerId: this.utilityService.getFromStorage('customerId'),
      callIncompleteReason: this.incompleteCallReason,
    };
    this.restAPIService
      .invoke(APIEndPoint.UPDATE_CALL_DISPOSE, null, reqBody)
      .subscribe(() => {
        this.moveUserToDashboard();
      });
  };

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    this.dialogRef.close();
  }
}
